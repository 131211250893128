<template>
  <div class="_app">
    <noscript>
      <img height="1" width="1" :src="imgurl" />
    </noscript>
    <router-view />
    <!-- <Install ref="install" /> -->
  </div>
</template>
<script>
// import Install from "@/components/install.vue";
export default {
  data() {
    return {
      faceBookCode: "130942279954265", //发送ajax请求动态的去获取像素代码，并复制给这个faceBookCode
      imgurl: "",
    };
  },
  components: {
    // Install,
  },
  created() {
    // if (window.location.search.includes("fbclid")) {
    //   window.location.href =
    //     window.location.origin +
    //     window.location.pathname +
    //     window.location.hash;
    // }
    // if (window.location.search.includes("adjust_referrer")) {
    //   window.location.href =
    //     window.location.origin +
    //     window.location.pathname +
    //     window.location.hash +
    //     window.location.search.replace("?", "&");
    // }
    // this.getFBPixed()
    // fbq('track', 'ViewContent');
    // function locationHashChanged() {
    //   location.reload()
    // }
    // window.onhashchange = locationHashChanged;
  },
  mounted() {},
  watch: {
    $route(to, from) {
      if (to.path === "/login") {
        window.onhashchange = this.locationHashChanged;
      }
    },
  },
  methods: {
    locationHashChanged() {
      location.reload();
    },
    getFBPixed() {
      this.imgurl =
        "https://www.facebook.com/tr?id=" +
        this.faceBookCode +
        "&ev=PageView&noscript=1";
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", this.faceBookCode);
      fbq("track", "PageView");
    },
  },
};
</script>
<style lang="scss" scoped>
html {
  touch-action: manipulation;
}
input,
textarea {
  -webkit-user-select: auto !important;
  -khtml-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  -o-user-select: auto !important;
  user-select: auto !important;
  -webkit-touch-callout: none !important;
}
select,
textarea,
input {
  font-size: 16px;
}
</style>
